
export default class User {
  constructor (email, senha, ip, host, tipoHost, versao, ResponseData, Status, Description) {
    this.senha = senha
    this.email = email
    this.ip = ip
    this.host = host
    this.tipoHost = tipoHost
    this.versao = versao,
    this.ResponseData = ResponseData,
    this.Description = Description,
    this.Status = Status

  }
}