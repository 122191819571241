<template>
    <v-app>
    

    <!--
        <v-toolbar
                app
                flat
                dense
                color="primary"
                dark
        >
            <v-toolbar-side-icon
                    @click.stop="drawer = !drawer"
                    class="hidden-lg-and-up"
                    :class="searching ? 'hidden-xs-only' : ''"
            />
            <v-menu :nudge-width="100" :class="searching ? 'hidden-xs-only' : ''">
 
            </v-menu>
            <v-spacer></v-spacer>

            

            <v-menu> 
                <v-btn icon slot="activator">
                    <v-avatar class="white" size="32">
                        <v-icon color="primary">person</v-icon>
                    </v-avatar>
                </v-btn>
                <v-list class="pa-0" light>
                  

                    <v-list-tile key="profile" >
                        <v-list-tile-action>
                            <v-icon>person</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title>Alterar Senha</v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>

                    <v-list-tile key="lock_open" >
                        <v-list-tile-action>
                            <v-icon>lock_open</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title>Logout</v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                </v-list>
            </v-menu>
        </v-toolbar>

        -->

        <v-content>
            <router-view/>
        </v-content>


   
    </v-app>
</template>

<script>
    export default {
        name: 'VuebaseLayout',

        data() {
            return {
                appName: process.env.VUE_APP_APP_NAME,
                drawer: true,
                fixed: false,
                analyticsItems: [
                    /*
                    {
                        icon: 'dashboard',
                        title: 'Dashboard',
                        link: '/dashboard/indicators'
                    },
                    {
                        icon: 'event',
                        title: 'Events',
                        link: ''
                    },
                    {
                        icon: 'comment',
                        title: 'Notifications',
                        link: ''
                    }
                    */
                ],
                developItems: [
                    /*
                    {
                        icon: 'supervisor_account',
                        title: 'Authentification',
                        link: ''
                    },
                    {
                        icon: 'storage',
                        title: 'Database',
                        link: ''
                    },
                    {
                        icon: 'perm_media',
                        title: 'Storage',
                        link: ''
                    },
                    {
                        icon: 'public',
                        title: 'Hosting',
                        link: ''
                    },
                    {
                        icon: 'functions',
                        title: 'Functions',
                        link: ''
                    }
                    */
                ],
                miniVariant: false,
                right: true,
                rightDrawer: false,
                tabs: null,
                tabsItems: [
                    {
                        id: 1,
                        title: 'Indicators',
                        link: 'indicators'
                    },
                    {
                        id: 2,
                        title: 'Backup',
                        link: 'backup'
                    },
                    {
                        id: 3,
                        title: 'Logs',
                        link: 'logs'
                    }
                ],
                menuItems: ['', '', ''],
                searching: false,
                search: ''
            }
        },

        methods: {
            onBlur() {
                this.searching = false
                this.search = ''
            },

            searchBegin() {
                this.searching = true
                setTimeout(() => document.querySelector('#search').focus(), 50)
            },

            searchEnd() {
                this.searching = false
                this.search = ''
                document.querySelector('#search').blur()
            }
        }
    }
</script>

<style scoped lang="stylus">

    .elementor-kit-12{
        --e-global-color-primary:#F07D00;
        --e-global-color-secondary:#315886;
        --e-global-color-text:#000000;
        --e-global-color-accent:#F1F9FF;
        --e-global-typography-primary-font-family:"Poppins";
        --e-global-typography-primary-font-size:46px;--e-global-typography-primary-font-weight:600;
        --e-global-typography-secondary-font-family:"Poppins";--e-global-typography-secondary-font-weight:400;
        --e-global-typography-text-font-family:"Poppins";--e-global-typography-text-font-weight:400;--e-global-typography-accent-font-family:"Poppins";
        --e-global-typography-accent-font-weight:500;color:var( --e-global-color-text );font-family:"Open Sans", Sans-serif;
        }
    .elementor-kit-12 a{color:var( --e-global-color-primary );
        font-family:"Open Sans", Sans-serif;font-size:16px;
    }
    .elementor-kit-12 a:hover{color:var( --e-global-color-secondary );
    font-family:"Open Sans", Sans-serif;
    }
    .elementor-kit-12 h1{color:#FFFFFF;font-family:"Poppins", Sans-serif;font-size:46px;}
    .elementor-kit-12 h2{color:var( --e-global-color-secondary );font-family:"Poppins", Sans-serif;font-size:46px;}
    .elementor-kit-12 h3{color:var( --e-global-color-secondary );font-size:30px;}.elementor-kit-12 h4{color:var( --e-global-color-secondary );font-family:"Poppins", Sans-serif;font-size:20px;}
    .elementor-kit-12 h5{color:var( --e-global-color-secondary );font-family:"Poppins", Sans-serif;font-size:16px;}
    .elementor-kit-12 h6{color:var( --e-global-color-secondary );font-family:"Poppins", Sans-serif;font-size:14px;}
    .elementor-kit-12 button,
    .elementor-kit-12 input[type="button"],
    .elementor-kit-12 input[type="submit"],
    .elementor-kit-12 
    .elementor-button{font-family:"Open Sans", Sans-serif;font-size:16px;color:#FFFFFF;background-color:var( --e-global-color-primary );}
    .elementor-kit-12 button:hover,
    .elementor-kit-12 button:focus,
    .elementor-kit-12 input[type="button"]:hover,
    .elementor-kit-12 input[type="button"]:focus,
    .elementor-kit-12 input[type="submit"]:hover,
    .elementor-kit-12 input[type="submit"]:focus,
    .elementor-kit-12 .elementor-button:hover,.elementor-kit-12 
    .elementor-button:focus{color:#FFFFFF;background-color:var( --e-global-color-text );}
    .elementor-kit-12 label{color:var( --e-global-color-text );font-family:"Open Sans", Sans-serif;font-size:15px;}
    .elementor-kit-12 input:not([type="button"]):not([type="submit"]),
    .elementor-kit-12 textarea,
    .elementor-kit-12 
    .elementor-field-textual{font-family:"Open Sans", Sans-serif;font-size:15px;color:var( --e-global-color-secondary );background-color:#FFFFFF;}
    .elementor-section.elementor-section-boxed > 
    .elementor-container{max-width:1140px;}
    .elementor-widget:not(:last-child){margin-bottom:20px;}@media(max-width:1024px){
    .elementor-section.elementor-section-boxed > 
    .elementor-container{max-width:1024px;}}@media(max-width:767px){
    .elementor-kit-12{--e-global-typography-primary-font-size:22px;--e-global-typography-secondary-font-size:22px;--e-global-typography-text-font-size:14px;font-size:16px;}
    .elementor-kit-12 a{font-size:16px;}
    .elementor-kit-12 h1{font-size:64px;}.elementor-kit-12 h2{font-size:64px;}.elementor-kit-12 button,.elementor-kit-12 input[type="button"],
    .elementor-kit-12 input[type="submit"],
    .elementor-kit-12 .elementor-button{font-size:16px;}
    .elementor-section.elementor-section-boxed > 
    .elementor-container{max-width:767px;}
    
    
    
        }/* Start custom CSS */html,
body {
    overflow-x: hidden;
}

body::-webkit-scrollbar{
	width: 5px;
	background-color: #F07D00;
}

body::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: #066296;
}/* End custom CSS */

    .bottom-menu {
        position: absolute;
        width: 100%;
        bottom: 0;
    }

    .searching {
        overflow: hidden;
        width: 208px;
        padding-left: 8px;
        transition: $primary-transition;
    }

    .searching--closed {
        padding-left: 0;
        width: 0;
    }

    .searching > * {
        right: 8px;
    }

    .searching--closed > * {
        display: none;
    }

 
    .list-border-bottom {
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
</style>
